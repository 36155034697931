<template>
  <div class="page">
    <div style="display: flex; justify-content: center">
      <v-img
        width="90%"
        src="https://firebasestorage.googleapis.com/v0/b/colab-lat.appspot.com/o/colab-mai.png?alt=media&token=c7e0184e-8072-4121-8de2-60559d1b5a18"
      >
      </v-img>
    </div>

    <p style="text-align: justify">
      Hemos recibido tu solicitud de registro y estamos procesando tus datos
      para activar tu cuenta. Te notificaremos al correo ingresado sobre los
      avances del proceso.
    </p>

    <div style="display: flex; justify-content: flex-end" class="mt-5">
      <v-btn color="primary" @click="startProcess">Nueva solicitud</v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "success",

  methods: {
    startProcess() {
      this.$router.push({
        path: "/start",
      });
    },
  },
};
</script>