import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import 'vuetify/dist/vuetify.min.css';
Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        dark: false,
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#1e2428",
                secondary: "#5170b5",
                accent: "#1e2428",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
            },
            dark: {
                primary: "#1e2428",
                secondary: "#5170b5",
                accent: "#1e2428",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107",
            },
        },
    },
    lang: {
        current: "es",
    },
    icons: {
        iconfont: "fa",
    },
});
