<template>
  <div
    class="page"
    style="margin-left: 0 !important; margin-right: 0 !important"
  >
    <v-snackbar top color="primary" timeout="5000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>

    <!-- <p class="my-0 py-0" style="font-size: 20px; font-weight: bold">
      Completa la información
    </p>
    <small style="text-align: start"
      >Asegurate que la información ingresada es la correcta ya que esta se
      mostrará en el sitio de Colab</small
    > -->
    <!-- <v-container class="mt-5" fluid>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col class="my-0 py-0" cols="12" sm="12" md="12">
            <v-text-field
              v-model="business.name"
              :rules="required"
              label="Nombre comercial de la empresa"
              filled
            >
            </v-text-field>
          </v-col>

          <v-col class="my-0 py-0" cols="12" sm="12" md="12">
            <v-text-field
              v-model="business.email"
              :rules="required"
              label="Correo electrónico"
              filled
            >
            </v-text-field>
          </v-col>

          <v-col class="my-0 py-0" cols="12" sm="12" md="12">
            <v-text-field
              v-model="business.phone"
              :rules="required"
              label="Número de celular"
              filled
            >
            </v-text-field>
          </v-col>

          <v-col class="my-0 py-0" cols="12" sm="12" md="12">
            <v-text-field
              v-model="business.password"
              :rules="required"
              label="Contraseña"
              filled
            >
            </v-text-field>
          </v-col>

          <v-col class="my-0 py-0" cols="12" sm="12" md="12">
            <v-text-field
              v-model="business.confirmPassword"
              :rules="required"
              label="Confirmar contraseña"
              filled
            >
            </v-text-field>
          </v-col>

          <v-col
            class="my-0 py-0"
            cols="12"
            sm="12"
            md="12"
            style="display: flex; justify-content: flex-end"
          >
            <v-btn color="primary" @click="send">Enviar solicitud</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container> -->

    <v-stepper flat v-model="e6" vertical>
      <v-stepper-step :complete="e6 > 1" step="1">
        Información de contacto
        <small>Datos del representante legal del comercio</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-form ref="form-e1" v-model="valid" lazy-validation>
          <v-text-field
            v-model="business.ownerName"
            :rules="required"
            label="Nombre del representante"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.ownerPhone"
            :rules="required"
            label="Número de celular"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.ownerCountry"
            :rules="required"
            label="Nacionalidad"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.ownerOccupation"
            :rules="required"
            label="Profesión"
            filled
          >
          </v-text-field>

          <v-textarea
            v-model="business.ownerAddress"
            :rules="required"
            rows="3"
            label="Dirección actual"
            filled
          >
          </v-textarea>
        </v-form>

        <div style="display: flex; justify-content: flex-end">
          <v-btn color="primary" small @click="next(1, 2)"> Continuar </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Información del comercio
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-form ref="form-e2" v-model="valid" lazy-validation>
          <v-text-field
            v-model="business.name"
            :rules="required"
            label="Nombre del comercio"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.email"
            :rules="required"
            label="Correo electrónico"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.phone"
            :rules="required"
            label="Número de celular"
            filled
          >
          </v-text-field>

          <v-text-field
            v-model="business.rtn"
            :rules="required"
            label="RTN"
            filled
          >
          </v-text-field>

          <v-select
            v-model="business.countryId"
            :rules="required"
            :items="countries"
            @change="getCities"
            item-text="name"
            item-value="id"
            label="Seleccionar pais"
            filled
          >
          </v-select>

          <v-select
            v-model="business.cities"
            :rules="required"
            :items="cities"
            multiple
            item-text="name"
            item-value="id"
            label="Seleccionar ciudad"
            filled
          >
          </v-select>

          <v-textarea
            v-model="business.address"
            :rules="required"
            rows="3"
            label="Dirección actual"
            filled
          >
          </v-textarea>
        </v-form>

        <div style="display: flex; justify-content: flex-end">
          <v-btn
            color="primary"
            text
            small
            @click="previous(1)"
            :disabled="loading"
          >
            Anterior
          </v-btn>

          <v-btn color="primary" small @click="next(2, 3)" :loading="loading">
            Continuar
          </v-btn>
        </div>
      </v-stepper-content>

      <!-- <v-steppr-step :complete="e6 > 3" step="3">
        Select an ad format and name ad unit
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
        <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
        <v-btn text> Cancel </v-btn>
      </v-stepper-content>

      <v-stepper-step step="4"> View setup instructions </v-stepper-step>
      <v-stepper-content step="4">
        <v-card color="grey lighten-1" class="mb-12" height="200px"></v-card>
        <v-btn color="primary" @click="e6 = 1"> Continue </v-btn>
        <v-btn text> Cancel </v-btn>
      </v-stepper-content>e -->
    </v-stepper>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
export default {
  name: "start",
  //   components: {
  //     HelloWorld,
  //   },

  data() {
    return {
      e6: 1,
      valid: true,
      snackbarText: "",
      snackbar: false,
      countries: [],
      cities: [],
      loading: false,
      business: {
        name: "",
      },
      required: [(v) => !!v || "Campo requerido"],
    };
  },

  methods: {
    async next(current, next) {
      if (this.$refs[`form-e${current}`].validate()) {
        if (next < 3) {
          this.e6 = next;
        } else {
          try {
            this.loading = true;

            console.log(this.business);

            await axios.post(
              "https://us-central1-colab-lat.cloudfunctions.net/businessesPreRegistration",
              {
                data: this.business,
              }
            );
            this.$router.push({
              path: "/success",
            });
          } catch (error) {
            console.log(error);
            this.snackbarText = error;
            this.snackbar = true;
            this.loading = false;
          }
        }
      }
    },

    previous(index) {
      this.e6 = index;
    },

    getCities(id) {
      let country = this.countries.find((e) => e.id == id);
      this.cities = country.cities;
    },

    send() {
      if (this.$refs.form.validate()) {
        this.$router.push({
          path: "/success",
        });
      } else {
        this.snackbarText = "Ingresa toda la información solicitada.";
        this.snackbar = true;
      }
    },
  },

  async mounted() {
    try {
      let request = await axios.post(
        "https://us-central1-colab-lat.cloudfunctions.net/countriesGet",
        {
          data: {},
        }
      );

      this.countries = request.data.result.countries;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>