<template>
  <div class="page">
    <div style="display: flex; justify-content: center">
      <v-img
        width="90%"
        max-height="500px"
        src="https://firebasestorage.googleapis.com/v0/b/colab-lat.appspot.com/o/colab-mai.png?alt=media&token=c7e0184e-8072-4121-8de2-60559d1b5a18"
      >
      </v-img>
    </div>

    <p style="text-align: justify" class="mt-5">
      Estamos emocionados de ver a tu marca formar parte de la comunidad de
      Colab. Impulsamos tu crecimiento conectándote con una comunidad activa de
      nano influencers que no solo dará visibilidad a tu marca, sino que también
      te ayudará a alcanzar a tu audiencia ideal. Lo mejor es que esa misma
      audiencia generará contenido orgánico auténtico sobre tu marca, creando un
      impacto genuino y duradero. Dale clic en unirme para siguientes pasos
    </p>

    <div style="display: flex; justify-content: flex-end">
      <v-btn color="primary" @click="startProcess">Unirme</v-btn>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },

  methods: {
    startProcess() {
      this.$router.push({
        path: "/start",
      });
    },
  },
};
</script>