import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'
import start from '../views/start.vue'
import success from '../views/success.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/start',
    name: 'start',
    component: start
  },

  {
    path: '/success',
    name: 'success',
    component: success
  }
]

const router = new VueRouter({
  routes
})

export default router
